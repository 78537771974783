<template>
  <USelectMenu
    class="mt-2.5 lg:w-40"
    v-model="appStore.selectedNavigation"
    :options="appStore.navSectionList"
    value-attribute="id"
  />
</template>

<script setup>
const appStore = useAppStore();

watch(() => appStore.selectedNavigation, (value) => {
  if (value === 0){
    navigateTo('/')
  } else if (value === 1){
    navigateTo('/hub')
  }
});
</script>
