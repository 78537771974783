export const useTeamNotificationsStore = defineStore('team-notifications', () => {
  const hasUnread = ref<boolean>(false);

  async function load() {
    hasUnread.value = (await Api.new().platformNotificationsHasUnread({})).has_unread;
  }

  /**
   * loads notifications every 3 seconds
   */
  async function start() {
    await load();
    setInterval(load, 10000);
  }

  return {
    hasUnread,
    start,
  };
});
