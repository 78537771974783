<script setup lang="ts">
const router = useRouter()
const { isDashboardSearchModalOpen } = useUIState()
const { metaSymbol } = useShortcuts()
const { user } = useAuth();
const items = computed(() => [
  [
    {
      label: 'My Account',
      icon: 'i-heroicons-user-circle',
      click: () => {
        router.push('/my-account/profile')
      }
    },
    {
      label: 'Command menu',
      icon: 'i-heroicons-command-line',
      shortcuts: [metaSymbol.value, 'K'],
      click: () => {
        isDashboardSearchModalOpen.value = true
      }
    }
  ],
  [{
    label: 'Sign out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: () => {
      useAuth().logout()
    }
  }]
])
</script>

<template>
  <UColorModeButton />
  <UDropdown v-if="user" mode="hover" :items="items" :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }" :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full">
    <template #default="{ open }">
      <UButton color="gray" variant="ghost" class="w-full" @click="router.push('/my-account/profile')" :label="user.name" :class="[open && 'bg-gray-50 dark:bg-gray-800']">

        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          Signed in as
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ user.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
