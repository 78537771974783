<template>
  <div>
    <UDashboardLayout>
      <UDashboardPanel
        :width="250"
        :resizable="{ min: 200, max: 300 }"
        collapsible
      >
        <UDashboardSidebar class="space-y-10">
          <template #header>
            <LayoutApplicationLogo class="mb-5" />


            <div class="flex justify-center mb-5">
              <LayoutSectionMenu v-if="expandMenu" />
            </div>
          </template>

          <UDashboardSidebarLinks :links="appStore.navigation" />

          <div class="flex-1" />

          <UDashboardSidebarLinks :links="[
            {
              label: 'Notifications',
              to: '/notifications',
              chip: hasUnreadNotifications ? 'red' : 'gray'
            },
            {
              label: 'Integrations',
              icon: 'i-heroicons-cube',
              to: '/integrations'
            },currentTeam]" />

          <UDivider class="sticky bottom-0" />

          <template #footer>
            <LayoutUserDropdown />
          </template>
        </UDashboardSidebar>
      </UDashboardPanel>
      <slot />

      <ClientOnly>
        <LazyUDashboardSearch :groups="groups" />
      </ClientOnly>
    </UDashboardLayout>
  </div>
</template>
<script setup lang="ts">
const appStore = useAppStore()
const api = new Api
const currentTeam = ref({
  label: 'loading...',
  icon: 'i-heroicons-building-office-2',
  to: '/my-account/teams'
})
const groups = computed(() => [
  {
    key: 'Sections',
    label: 'Sections',
    commands: appStore.navSectionList
  },
  {
    key: 'links',
    label: 'Go to',
    commands: appStore.navigation.map((link: any) => {
      if (link.children) {
        return link.children.map((child: any) => {
          return {
            label: link.label + ' > ' + child.label,
            icon: link.icon,
            shortcuts: child.shortcuts,
            to: child.to
          }
        })
      }
      return link
    }).flat()
  }
])

if (localStorage.getItem('leaveMenu')) {
  appStore.leaveMenu = localStorage.getItem('leaveMenu') === 'true'
}
const expandMenu = appStore.leaveMenu ? ref(false) : ref(true)

const handleToogle = () => {
  appStore.leaveMenu = !appStore.leaveMenu
  localStorage.setItem('leaveMenu', appStore.leaveMenu)
}

const menuMouseOver = () => {
  expandMenu.value = true
}

const menuMouseLeave = () => {
  if (appStore.leaveMenu) {
    expandMenu.value = !expandMenu.value
  }
}
const teamNotificationsStore = useTeamNotificationsStore()
const hasUnreadNotifications = ref<boolean>(false)
watch(() => teamNotificationsStore.hasUnread, (hasUnread:any) => {
  hasUnreadNotifications.value = hasUnread
})

onMounted(async () => {
  try {
    const { data } = await api.teamsIndex()
    const team = data.find((team: any) => team.is_current === true)

    if (!team) {
      return
    }

    teamNotificationsStore.start()
    currentTeam.value = {
      label: team.name,
      icon: 'i-heroicons-building-office-2',
      to: `/my-account/team-settings/${team.id}/overview`
    }

  } catch (error) {
    console.error('Failed to fetch teams:', error)
  }

})

// onBeforeRouteLeave(() => {
//   appStore.sidebarOpen = !appStore.sidebarOpen;
// });

</script>
